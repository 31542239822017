import React, { Component } from 'react'
import { Terminal as XTermTerminal, IBuffer, ITerminalAddon } from 'xterm'
import "xterm/css/xterm.css"
import './Terminal.css'
import { AttachAddon } from 'xterm-addon-attach'
import { FitAddon } from 'xterm-addon-fit'
import { SerializeAddon } from 'xterm-addon-serialize'

/**
 * wrapper around xterm
 * to ease configuration and setup
 */
class TerminalWrapper {

  constructor(_container, onHistoryChanged, {
    rows = 10,
    background = '#2e2e2e',
    foreground = '#f3f3f3'
  } = {}) {
    this._container =
    this._onHistoryChanged = onHistoryChanged

    const xterm = new XTermTerminal({
      cols: 180,
      rows
    })
    this.xterm = xterm

    // TODO make sure font is available with FontFace?
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/font

    // https://github.com/vercel/hyper/blob/canary/lib/components/term.tsx
    // https://github.com/vercel/hyper/blob/ffd4eb46e3923061be6549796f00d437fa0aaa0f/lib/reducers/ui.ts
    const options = {
      cursorStyle: 'block',
      cursorBlink: false,
      fontFamily: 'Menlo, "DejaVu Sans Mono", "Lucida Console", monospace',
      fontSize: 12,
      fontWeight: 'normal',
      fontWeightBold: 'bold',
      lineHeight: 1.5,
      letterSpacing: 0,
      windowsMode: false,
      scrollback: 1000
    }

    Object.keys(options).forEach(option => xterm.setOption(option, options[option]))

   //  xterm.setOption('fontFamily', 'Menlo, "DejaVu Sans Mono", "Lucida Console", monospace')
    xterm.setOption('theme', {
      // background: '#f1f3f2',
      foreground: foreground,
      background,
      cursorColor: '#F81CE5',
      cursorAccentColor: '#000',
      borderColor: '#333',
      selectionColor: 'rgba(248,28,229,0.3)',
      colors: {
        black: '#000000',
        red: '#C51E14',
        green: '#1DC121',
        yellow: '#C7C329',
        blue: '#0A2FC4',
        magenta: '#C839C5',
        cyan: '#20C5C6',
        white: '#C7C7C7',
        lightBlack: '#686868',
        lightRed: '#FD6F6B',
        lightGreen: '#67F86F',
        lightYellow: '#FFFA72',
        lightBlue: '#6A76FB',
        lightMagenta: '#FD7CFC',
        lightCyan: '#68FDFE',
        lightWhite: '#FFFFFF'
      },
    })

    const serializeAddon = new SerializeAddon();
    xterm.loadAddon(serializeAddon);

    const fitAddon = new FitAddon();
    xterm.loadAddon(fitAddon);

    xterm.open(_container);

    // TODO kills scroll
    fitAddon.fit()
  }
  async attach(socketUrl) {

    const protocol = (window.location.protocol === 'https:') ? 'wss://' : 'ws://';
    const socket = new WebSocket(`${protocol}${socketUrl}`);

    let x = new AttachAddon(socket);
    this.xterm.loadAddon(x);
    this.socket = socket

    // TODO handle
    this.socket.addEventListener('close', () => {
      // alert('connection closed')
    })

    this.socket.addEventListener('error', () => {
      // alert('connection error')
    })

  }
}

export default class TerminalUI extends Component {
  componentDidMount = async () => {
    const { background = '#2e2e2e' } = this.props
    const { socketUrl, onTerminalReady = () => {}, content, rows } = this.props
    const { terminalContainer } = this
    const term = new TerminalWrapper(terminalContainer, this.props.onHistoryChanged, {
      rows,
      background
    })
    if (socketUrl){
      await term.attach(socketUrl)
    }
    if (content) {
      term.xterm.write(content)
    }
    onTerminalReady(term)
  }
  render() {
    const { background = '#2e2e2e', padding = 15 } = this.props
    return (
      <div
        className="terminal"
        style={{
          flex: 1,
          backgroundColor: background,
          boxSizing: 'content-box',
          paddingLeft: padding,
          paddingTop: padding,
        }}
        ref={ref => {
          this.terminalContainer = ref
        }}
      />
    )
  }
}
