import React, { useEffect, useState } from 'react'
import Checkbox from '../components/Checkbox';
import { getAPI } from '../js/WsApi';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { useAppContext } from '../libs/contextLib';

export default function Sessions() {

  const [sessions, setSessions] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [checkedTableRows, setCheckedTableRows] = useState({})

  const { config } = useAppContext();
  const api = getAPI(config)

  const onLoad = () => {
    loadSessions(api)
  }

  function onUnmount(){
    api.dispose()
  }

  useEffect(() => {
    onLoad();
    return onUnmount
  }, []);

  async function loadSessions(api) {
    const sessions = await api.listSessions()
    setSessions(sessions)
  }

  let location = useLocation();
  let history = useHistory()
  function handleSelectSession(sessionId) {
    history.push(`${location.pathname}/${sessionId}`)
  }

  return (
    <div style={{ flex: 1 }}>
      <h2>Sessions</h2>
      <table className="table" style={{ marginTop: 10 }}>
        <thead>
          <tr>
            <th><Checkbox checked={selectAll} onChange={(e) => {
              let val = {}
              sessions.map(s => val[s.Id] = !selectAll)
              setCheckedTableRows(val)
              setSelectAll(!selectAll)
            }}/></th>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Created</th>
            <th>Host</th>
            <th>Environment</th>
            <th># Commands</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map(({ host, environmentId, sessionId, name = 'Untitled', description = 'no description', created = Date.now(), state = 'active' }) =>
            <tr key={sessionId}>
              <td><Checkbox checked={!!checkedTableRows[sessionId]} onChange={() => setCheckedTableRows({ ...checkedTableRows, [sessionId] : checkedTableRows[sessionId] ? false : true })}/></td>
              <td onClick={() => handleSelectSession(sessionId)} style={{ cursor: 'pointer' }}>{sessionId}</td>
              <td onClick={() => handleSelectSession(sessionId)} style={{ cursor: 'pointer' }}>{name}</td>
              <td>{description}</td>
              <td>{moment(created).format("DD.MM.YYYY hh:mm:ss")}</td>
              <td>{host}</td>
              <td>{environmentId}</td>
              <td>{7}</td>
              <td>{state}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}