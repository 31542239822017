import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'

export default class Markdown extends Component {
  render() {
    const { source, style } = this.props
    return (
      <div 
      style={{
        backgroundColor: '#f9f9f9',
        padding: 5,
        flex: 1,
        overflow: 'auto',
        ...style
      }}
      ref={this.props.ref}
      >
        <ReactMarkdown
          source={source}
          escapeHtml={true}
          /*
          renderers={{
            'heading': props => {
              return <Title h={props.level}>{props.children}</Title>
            },
            'code': (props) => {
              console.log('render code with props', props)
              return <CodeEditor source={props.value} language={props.language}/> 
            }
          }}
          */
        />
      </div>
    )
  }
}
