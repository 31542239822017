import React from 'react'
import Examples from '../components/Examples'
import TerminalPlayer from '../components/TerminalPlayer'

export function Landing(){
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: '10%', marginTop: 50 }}>
      <div>
        <h3 className="title is-3">Sandboxed environments in the cloud</h3>
        <TerminalPlayer 
          mode="static"
          history={[
            {
              sessionId: 'test',
              time: Date.now(),
              title: 'Session started',
              description: 'start hacking now!'
            },
            {
              sessionId: 'test',
              time: Date.now(),
              title: 'sudo apt get install <insert here>',
              description: `
- no config conflicts 💥
- no bloat 🗑️
- more CPU / RAM /DISK 🚀
              `
            },
            {
              sessionId: 'test',
              time: Date.now(),
              title: 'annotate sessions',
              description: 'with `markdown`'
            }
          ]}
        />  
      </div>
      <Examples />
    </div>
  )
}