import React from 'react';

import {
  Switch, Route
} from "react-router-dom"
import Login from './pages/Login';
import Pricing from './pages/Pricing';
import Dashboard from './pages/Dashboard';
import { Share } from './pages/Share';
import { Landing } from './partials/Landing';

function NoMatch(){
  return <div> Bad link :-( </div>
}

export default ({ provider, currentBlock }) => {
  return (
    <Switch>
    <Route path="/login">
      <Login />
    </Route>
    <Route path="/signup">
      <Login />
    </Route>
    <Route path="/pricing">
      <Pricing />
    </Route>
    <Route path="/s/:env" component={Share} />
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/">
      <Landing />
    </Route>
    <Route path="*">
      <NoMatch />
    </Route>
  </Switch>
  )
}