import React, { Component } from 'react'
import Icon from './Icon'

class Example extends Component {
  render() {
    const { example = {} } = this.props
    const { title, icon } = example
    return (
      <div className="card" style={{
        width: 340,
        height: 300,
        margin: 20,
        padding: 10
      }}>
        <div className="card-content">
          <div className="media-content">
            <p className="title is-4" style={{ display: 'flex', alignItems: 'center' }}><Icon icon={icon} style={{ margin: 4, padding: 2 }} />{title}</p>
            <p className="subtitle is-6">Coming Soon</p>
          </div>
        </div>
      </div>
    )
  }
}

export default class Examples extends Component {
  render() {
    return (
      <div style={{ marginTop: 150, flex: 1 }}>
        <h3 className="title is-3">Examples</h3>
        <div style={{ position: 'relative', paddingBottom: 100, width: '100%', height: '100%' }}>
          <div style={{ display: 'flex', position: 'absolute', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
            {
              [
                { title: 'Live Demos', icon: "coffee" },
                { title: 'Onboard Devs', icon: 'rocket' }, 
                { title: 'Reproduce Bugs', icon: 'bug' }, 
                { title: 'Trainings & Classes', icon: 'graduation-cap' }, 
                { title: 'Manage Scripts', icon: 'search' }, 
                { title: 'Interactive Tutorials', icon: 'lightbulb' }
              ].map(example => <Example example={example} />)
            }
          </div>
        </div>
      </div>
    )
  }
}
