import React, { Component } from 'react'
import PricingTable from '../components/PricingTable'
import Button from '../components/Button'

export default class Pricing extends Component {
  render() {
    return (
      <div>
        <PricingTable />
        <h1 class="title">
          Couldn't find what you were looking for?
        </h1>
        <div style={{ marginTop: 20, marginBottom: 20 }}>We have special conditions for schools, open source teams, startups and large organizations!</div>
        <Button>Contact Sales</Button>
      </div>
    )
  }
}
