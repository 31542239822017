import React, { useState } from 'react'
import Button from './Button'
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { useHistory, useLocation } from 'react-router-dom';
import Icon from './Icon';
import { onError } from '../libs/errorLib';
import { useFormFields } from '../libs/hooksLib';
import { FormGroup } from './form/FormGroup';
import { FormControl, ControlLabel } from './form/FormControl';

function RegisterForm() {

  const [isLoading, setIsLoading] = useState(false);
  
  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: '',
    confirmPassword: "",
    confirmationCode: "",
  })

  function validateForm() { 
    return (
    fields.email.length > 0 && 
    fields.password.length > 0 &&
    fields.password === fields.confirmPassword
    );
  }

  async function handleSubmit(event) { 
    event.preventDefault();

    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password
      })
      console.log('signup', newUser)

      // wait for confirm 
      // https://github.com/aws-amplify/amplify-js/issues/612
      // https://medium.com/@jacobjoyseba/redirect-user-using-amazon-cognito-confirmation-url-d8ccb11bac75

      // setNewUser(newUser);
    } catch (error) {
      onError(error)
    }
    
    setIsLoading(false);
  }

  function renderRegistrationForm() {
    return (
      <div className="Register" style={{ padding: 50, backgroundColor: 'white', borderRadius: 10 }}>
        <h3 className="title" style={{ textAlign: 'center', paddingTop: 5 }}>{'Register'}</h3>
        <hr className="login-hr" />
        <form
          style={{
            margin: '0 auto',
            maxWidth: 320
          }} 
          onSubmit={handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel> 
            <FormControl
              name="email"
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel> 
            <FormControl
              name="password"
              value={fields.password}
              onChange={handleFieldChange} type="password"
            />
          </FormGroup>
          <FormGroup controlId="confirmPassword" bsSize="large">
            <ControlLabel>Confirm Password</ControlLabel> 
            <FormControl
              name="confirmPassword"
              value={fields.confirmPassword}
              onChange={handleFieldChange} 
              type="password"
            />
          </FormGroup>
          <Button block bsSize="large" disabled={!validateForm() || isLoading} type="submit">
          {isLoading && <Icon icon="spinner" className="spinning" />} 
            Register
          </Button> 
        </form>
      </div>
    )
  }

  return (
    renderRegistrationForm()
  )
}

function SignInForm() {

  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: ''
  })
  const [passwordChange, setNewPassword] = useState(false);
  const [user, setUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { userHasAuthenticated } = useAppContext();

  const history = useHistory()

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) { 
    event.preventDefault();

    setIsLoading(true);

    // https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/Auth/RequireNewPassword.tsx
    if (passwordChange) {
      try {
        let currentUser = user;
        let newUser = await Auth.completeNewPassword(currentUser, 'Passw0rd!!');
        console.log('new user', newUser)
        // at this time the user is logged in if no MFA required
        userHasAuthenticated(true);
      } catch (e) {
        onError(e)
      }
      finally {
        setIsLoading(false);
        return
      }
    }

    try {
      let user = await Auth.signIn(fields.email, fields.password); 
      setIsLoading(false);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
        setNewPassword(true)
        setUser(user)
        return
      }
      userHasAuthenticated(true);
      console.log('login user', user)
      history.push("/dashboard");
    } catch (e) { 
      console.log('auth error', e)
      setIsLoading(false);
      onError(e)
    } 
  }

  return (
    <div className="Login" style={{ padding: 50, backgroundColor: 'white', borderRadius: 10 }}>
      <h3 className="title" style={{ textAlign: 'center', paddingTop: 5 }}>{passwordChange ? 'New Password' : 'Login'}</h3>
      <hr className="login-hr" />
      <form
        style={{
          margin: '0 auto',
          maxWidth: 320
        }} 
        onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel> 
          <FormControl
            name="email"
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel> 
          <FormControl
            name="password"
            value={fields.password}
            onChange={handleFieldChange} type="password"
          />
        </FormGroup>
        <Button block bsSize="large" disabled={!validateForm() || isLoading} type="submit">
         {isLoading && <Icon icon="spinner" className="spinning" />} 
          Login
        </Button> 
      </form>
    </div>
  )
}


export default function LoginForm() {
  const location = useLocation()
  console.log('location', location)
  return (
  <div>
    {
      location.pathname === '/login'
      ? <SignInForm />
      : <RegisterForm />
    }
    
  </div>
  )
}