import React, { useEffect, useState } from 'react'
import { getAPI } from '../js/WsApi'
import { useAppContext } from '../libs/contextLib';

function ProgressBar({ percentage }) {
  return <div>
    <progress className="progress is-primary is-small" value={percentage} max="100">15%</progress>
  </div>
}

export function SystemInfo({ style }) {

  const [systemInfo, setSystemInfo] = useState({
    cpu: 100
  })

  const { config } = useAppContext();

  async function onLoad(){
    const api = getAPI(config)
    const info = await api.getSystemInfo()
    const { load } = info
    const { currentload } = load
    console.log('received system info', info)
    setSystemInfo({
      cpu: currentload
    })
  }

  function onUnmount(){}

  useEffect(() => {
    onLoad();
    return onUnmount
  }, []);

  return (
    <div style={{...style}}>
      <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>System Info</div>
      <div>
        <span>CPU {`${systemInfo.cpu.toFixed(2)}%`}</span>
        <ProgressBar percentage={systemInfo.cpu}/>
      </div>
    </div>
  )
}