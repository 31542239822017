import React, { useState, useEffect } from 'react'
import Timeline from './Timeline'
import Terminal from './Terminal'
import Icon from './Icon'
import Countdown from './Countdown'
import Button from './Button'
import LoginForm from './LoginForm'
import { getAPI } from '../js/WsApi'
import ShareForm from './ShareForm'
import { useAppContext } from '../libs/contextLib'

export default function TerminalPlayer({ sessionId, socketUrl, mode, history : initHistory =[] }) {

  const [ isLoading, setIsLoading] = useState(true)
  const [ history, setHistory] = useState(initHistory)
  const [ overlay, setOverlay] = useState(undefined)

  const { config } = useAppContext();
  const api = mode !== 'static' ? getAPI(config) : undefined

  useEffect(() => {
    onLoad();
    return cleanup
  }, []);

  async function onLoad() {
    setIsLoading(true)
    if (mode !== 'static') {
      await getHistory()
    }
    setIsLoading(false)
  }

  function cleanup() {
    if (mode === 'static') {
      return
    }
    api.dispose()
  }
  

  async function getHistory(){
    const { result: history, subscription } = await api.subscribeHistory(sessionId)
    setHistory(history.map(([t, ty, command, description]) => ({
      sessionId,
      time: t,
      title: command,
      description: description || ''
    })))

    subscription.on('data', data => {
      console.log('received history subscription event', data)
      const [t, eventType, command, description] = data
      setHistory(history => [...history, {
        sessionId,
        time: t,
        title: command,
        description: description || ''
      }])
      console.log('history length', history.length)
    })

  }

  // called when a timeline entry's description is edited
  const handleDescriptionChanged = async (ev, text) => {
    console.log('description changed', ev, text)
    const {sessionId, time, title, description: descriptionOld = '' } = ev
    const result = await api.updateEventDescription(sessionId, [time, 'h', title, descriptionOld], text)
    const history = await api.getHistory(sessionId)
    setHistory(history.map(([t, ty, command, description]) => ({
      sessionId,
      time: t,
      title: command,
      description: description || ''
    })))
  }

  const renderOverlay = (type = 'share') => {
    return <div 
      className="terminal-overlay"  
      style={{
        width: '100%',
        height: 'calc(100% - 100px)',
        position: 'absolute',
        top: 50,
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, .15)',
        backdropFilter: 'blur(10px)',
        flex: 1,
      }}>
        {type === 'login'
        ? <LoginForm />
        : <ShareForm />
        }
    </div>
  }

  return (
    <div>
      <div style={{
        display: 'flex',
        marginTop: 10,
        flex: 1,
        height: 600,
      }}>
        <Timeline events={history} 
          onDescriptionChanged={handleDescriptionChanged}
          onPlayClicked={() => {
            alert('handle playback')
          }} 
        />
        <div
          style={{
            borderRadius: 4,
            width: 880,
            overflow: 'hidden',
            position: 'relative'
          }}
          className="terminal-container"
        >
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ backgroundColor: '#e9e9e9', padding: 5, margin: 0, display: 'flex', alignItems: 'center' }}>
              <div style={{ marginLeft: 'auto' }}> 
                <Button disabled={ mode === 'static' } onClick={() => setOverlay(overlay === 'share' ? undefined : 'share')}>Share</Button>
                <Button onClick={() => setOverlay(overlay === 'login' ? undefined : 'login')}>Log in</Button>
              </div>
            </div>
            {!isLoading && <Terminal
              socketUrl={socketUrl}
            />
            }
            <div style={{ backgroundColor: '#e9e9e9', padding: 5, margin: 0, display: 'flex', alignItems: 'center' }}>
              <span><strong>Mode:</strong> read-only</span>
              <span style={{ marginLeft: 10 }}><strong>Network:</strong> Off</span>
              <div style={{
                marginLeft: 10,
                fontWeight: 'bold'
              }}>
                Session:
                <Countdown style={{ fontSize: '1rem' }} />
              </div>
              <div className="tabs" style={{ display: 'inline-block', padding: 2, marginLeft: 'auto' }}>
                <ul style={{ margin: 0, padding: 0 }}>
                  <li className="is-active" style={{ fontSize: '1em' }}><a><Icon icon="terminal" />Terminal</a></li>
                  <li><a><Icon icon="code" />Code</a></li>
                </ul>
              </div>
            </div>
          </div>
          {overlay && renderOverlay(overlay)}
        </div>
      </div>
    </div>
  )
}
