import { EventEmitter } from 'events'

export const onReady = async socket => {
  return new Promise((resolve, reject) => {
    socket.onopen = function (event) {
      resolve(socket)
    };
  })
}

function addSocketListener(socket, type, handler) {
  socket.addEventListener(type, handler);
  let disposable = {
    dispose: () => {
      if (!handler) {
        // Already disposed
        return;
      }
      socket.removeEventListener(type, handler);
    }
  };
  return disposable
}

const host = process.env.REACT_APP_STAGE === 'production' ? `ec2-3-237-44-253.compute-1.amazonaws.com` : `127.0.0.1`

const getSocket = async (config) => {
  console.log('get socket for config', config)
  const socket = new WebSocket(`ws://${host}:${4000}?key1=value1`);
  await onReady(socket)
  return socket
}

const getSessionTerminal = (config, sessionId) => {
  const socketUrl = `${host}:${4000}?sessionId=${sessionId}`
  return {
    socketUrl
  }
}

// TODO add timeout handling
const makeRpcCall = (socket, sendId, method, params) => {
  let disposable
  let promise = new Promise(function(resolve, reject) {

    let subscription = new EventEmitter()

    disposable = addSocketListener(socket, 'message', (message) => {
      try {
        const rpcMessage = JSON.parse(message.data)
        const { id: receiveId, result, error } = rpcMessage

        // handle subscriptions
        // TODO change type to subscription
        if (rpcMessage.type === 'history_changed') {
          subscription.emit('data', rpcMessage.data)
          return
        }
        if (method.startsWith('subscribe')) {
          if (sendId === receiveId) {
            return resolve({
              subscription,
              result
            })
          }
        }

        // handle regular request / response communication
        if (sendId !== receiveId) {
          // ignore other responses
          return
        } else {
          disposable.dispose()
          if (error) {
            return reject(new Error(error.message))
          }
          return resolve(result)
        }
      } catch (error) {
        // console.log('parser error', error)
      }
    }) 
  })
  
  socket.send(JSON.stringify({
    jsonrpc: '2.0',
    id: sendId,
    method,
    params
  })); 

  return {
    disposable,
    promise
  }
}

export function createWsApi(config){
  let id = 0
  const target = {}

  let _disposables = []

  const dispose = () => {
    console.log('cleanup socket listeners', _disposables.length)
    _disposables.forEach(d => d.dispose());
  }

  let socket

  return new Proxy(target, {
    get: function(target, prop, receiver) {
      if (prop === 'then') {
        return target
      }
      if (prop === 'dispose') {
        return () => {
          dispose()
        }
      }
      if (prop === 'getSessionTerminal') {
        return (sessionId) => getSessionTerminal(config, sessionId)
      }
      return async function (...args) {
        const method = prop
        const params = args
        let sendId = ++id

        socket = socket || await getSocket(config)

        const { disposable, promise } = makeRpcCall(socket, sendId, method, params)

        _disposables.push(disposable)
        _disposables.push(addSocketListener(socket, 'close', () => dispose()));
        _disposables.push(addSocketListener(socket, 'error', () => dispose()));

        return promise
      }
    }
  });
}

export function getAPI(config){
  if (!config || !config.instances || config.instances.length === 0) {
    throw new Error('Can not create API - no instance configuration')
  }
  const { instances } = config
  const instance = instances[0]
  // there should be a 1:1 relationship between api proxy and instance
  const api = createWsApi(instance);
  return api
}