import React, { useState } from 'react';
import Icon from '../components/Icon';

export function Drowpdown({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={`dropdown ${isOpen ? 'is-active' : ''}`} >
      <div className="dropdown-trigger">
        <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => setIsOpen(!isOpen)}>
          <span>Actions</span>
          <span className="icon is-small">
            <Icon icon="angle-down" aria-hidden="true"/>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {children}
        </div>
      </div>
    </div>
  )
}

export function DrowpdownItem({ label, onClick=()=>{} }) {
  return (
    <div 
      className="dropdown-item" 
      onClick={(e) => {
        e.preventDefault(); 
        e.stopPropagation(); 
        onClick();
      }} 
      style={{ cursor: 'pointer' }}
    >
      {label}
    </div>
  )
}