
const API_ENDPOINT = 'https://8wganum0o9.execute-api.us-east-1.amazonaws.com/dev'

export const getInstances = async () => {
  const url = `${API_ENDPOINT}/instance`
  try {
    const response = await fetch(url, {
      method: 'GET',
    })
    const result = await response.json()
    return result
  } catch (error) {
    console.log('create error', error)
  }
}

export const shareEnvironment = async (data = {}) => {
  const url = `${API_ENDPOINT}/environment`
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data)
    })
    const result = await response.json()
    return result
  } catch (error) {
    console.log('create error', error)
  }
}

export const getEnvironmentDetails = async (shortId) => {
  const url = `${API_ENDPOINT}/environment/${shortId}`
  try {
    const result = await fetch(url, {
      method: 'GET'
    })
    const details = await result.json()
    return details
  } catch (error) {
    console.log('fetch error', error)
  }
}

export const createSession = async () => {
  const API = 'http://localhost:3003'
  const url = `${API}/sessions`
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify([])
    })
    const result = await response.json()
    const { sessionId } = result
    console.log('create result', result)
    // history.push(`/dashboard/environments/${sessionId}`)
  } catch (error) {
    console.log('create error', error)
  }
}

export const getAllSessions = async () => {
  try {
    const url = `${API_ENDPOINT}/sessions`
    try {
      const result = await fetch(url, {
        method: 'GET'
      })
      const sessions = await result.json()
      return sessions
    } catch (error) {
      console.log('create error', error)
    }
  } catch (error) {

  }
}

export const getSession = async (sessionId) => {
  try {
    const url = `${API_ENDPOINT}/sessions/${sessionId}`
    try {
      const result = await fetch(url, {
        method: 'GET'
      })
      const session = await result.json()
      return session
    } catch (error) {
      console.log('fetch error', error)
    }
  } catch (error) {

  }
}

export const saveSession = async (sessionId, data) => {
  const url = `${API_ENDPOINT}/sessions/${sessionId}`
    try {
      /*
      if (!this.hasChanged()) {
        return
      }
      */
      console.log('auto save')
      const result = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data)
      })
      // TODO this.setChecked()
      console.log('save session result', await result.json())
    } catch (error) {
      console.log('save error', error)
    }
}

export const saveSessionMetaData = async (sessionId, data) => {
  const url = `${API_ENDPOINT}/sessions/meta/${sessionId}`
    try {
      const result = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data)
      })
      console.log('get result', await result.json())
    } catch (error) {
      console.log('create error', error)
    }
}

export const deleteSession = async (sessionId) => {
  const url = `${API_ENDPOINT}/sessions/${sessionId}`
  try {
    const response = await fetch(url, {
      method: 'DELETE'
    })
    const result = await response.json()
    console.log('delete result', result)
  } catch (error) {
    console.log('create error', error)
  }
}