import React from 'react'
import Button from './Button'

export function ImportModal({ info }) {
  const userName = 'Alina'

  const { data } = info
  const { creator, environment, config } = data
  const { name, description } = environment
 
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ marginBottom: 10 }}>Hi <strong>{userName}</strong>!</div>
      <div>{creator} wants to share the environment <strong>"{name}"</strong> with you.</div>
      <br />
      <div>Description:</div>
      <div style={{ padding: 10 }}>
        <i>
          {description}
        </i>
      </div>
      <div>Settings:</div>
      <div style={{ flex: 1, padding: 10 }}>
        <div>Hosting: <strong>They</strong> </div>
        <div><span style={{ color: '#666', fontSize: '.85rem' }}>The instance is free for you to use but the host can access or terminate the environment at any time.</span></div>
        <div>Access: <strong>Clone</strong> </div>
        <div><span style={{ color: '#666', fontSize: '.85rem' }}>You have your own full copy of the environment that is not shared with anyone else.</span></div>
        <div>Session: <strong>Unlimited</strong> </div>
        <div><span style={{ color: '#666', fontSize: '.85rem' }}>There are no limits on session length or number of sessions.</span></div>

      </div>
      <div>
        <div style={{ fontWeight: 'bold', color: 'orange'}}>
          WARNING: never enter any confidential information in untrusted environments:
        </div>
        <a>You can see the full environment changelog here</a>
      </div>
      
    </div>
  )
}
