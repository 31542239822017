import React, { useEffect, useState } from 'react'
import SideBar from '../components/SideBar'
import Environments from '../partials/Environments'
import Session from '../partials/Session'
import { Route, Switch } from 'react-router-dom'
import Sessions from '../partials/Sessions'
import { useLocation } from 'react-router-dom'
import { getInstances } from '../js/Api'
import { AppContext } from '../libs/contextLib'

function Account() {
  return <div>your account</div>
}

function NoMatch() {
  let location = useLocation();
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default function Dashboard({ match }) {
  // TODO nav to login when no session

  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState(undefined)

  const navItems = [
    {to: '/dashboard/environment', label: 'Environments', icon: "list"},
    {to: '/dashboard/search', label: 'Search', icon: "search"},
    {to: '/dashboard/account', label: 'Account', icon: "user"}
  ]

  useEffect(() => {
    onLoad();
    // return onUnmount
  }, []);

  const onLoad = async () => {
    setIsLoading(true)
    const instances = await getInstances()
    setConfig({
      instances
    })
    setIsLoading(false)
  }

  function renderDashboard() {
    return (
      <AppContext.Provider value={{ config }}>
        <Switch>
          <Route path={match.url + "/"}  exact component={Environments}/>
          <Route path={match.url + "/environment/:eid/session/:sid"} component={Session} />
          <Route path={match.url + "/environment/:eid/session"} component={Sessions} />
          <Route path={match.url + "/environment"} exact component={Environments} />
          <Route path={match.url + "/account"} exact component={Account} />
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </AppContext.Provider>
    )
  }

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%', flex: 1, backgroundColor: '#f9f9f9' }}>
      <SideBar navItems={navItems}/>
      <main style={{
        padding: 20,
        display: 'flex',
        overflow: 'scroll',
        width: '100%',
        flex: 1,
      }}>
        {isLoading
        ? <div>Loading...</div>
        : renderDashboard()
        }
      </main>
    </div>
  )
}
