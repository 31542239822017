import React, { useEffect, useState } from 'react';
import TerminalPlayer from '../components/TerminalPlayer';
import { useParams } from 'react-router-dom';
import Editable from '../components/InplaceEdit';
import { getAPI } from '../js/WsApi';
import Icon from '../components/Icon';
import Button from '../components/Button';
import EventList from '../js/EventList';

import { useAppContext } from '../libs/contextLib';
import { CommandBlock } from '../components/CommandBlock';

function SessionTitle({ title = "Untitled", onCommit }) {
  return (
    <div>
      <Editable type="input" onCommit={onCommit}>
        <h2
          className="title is-2"
          style={{
            backgroundColor: 'rgba(125, 255, 165, 0.18)',
            display: 'inline-block',
          }}>
            {title}
        </h2>
      </Editable>
    </div>
  )
}

function SessionDescription({ text, onCommit }) {
  return <Editable text={text} onCommit={onCommit} />
}

function DocumentView({ sessionId }) {

  const [ history, setHistory] = useState([])

  const { config } = useAppContext();
  const api = getAPI(config)

  useEffect(() => {
    onLoad();
    return onUnmount
  }, []);

  async function onLoad() {
    await getHistory()
  }

  function onUnmount() {
    api.dispose()
  }

  /*
  function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }
  */

  async function getHistory(){
    const history = await api.getHistory(sessionId)
    const output = await api.getOutput(sessionId)

    // download('output.json', JSON.stringify(output, null, 2))
    
    // approach 1: merge everything between two prompts and render as terminal
    const eventList = new EventList(history, output) 
    const events = eventList.getEvents()    
    
    setHistory(events)
  }

  return (
  <>
    {history.map(ev => (
      <div key={ev.time}>
        <CommandBlock time={undefined /*ev.time*/} title={ev.command} description={ev.description} output={ev.output} />
      </div>
    ))}
  </> 
  )
    
}

export default function Session() {

  const [isLoading, setIsLoading] = useState(true)
  const [name, setName] = useState('Untitled')
  const [description, setDescription] = useState('No Description')
  const [mode, setMode] = useState('column')

  const { eid: environmentId, sid: sessionId } = useParams();

  const { config } = useAppContext();
  const api = getAPI(config)
  
  const { socketUrl } = api.getSessionTerminal(sessionId)

  useEffect(() => {
    onLoad();
    return onUnmount
  }, []);


  async function onLoad() {
    setIsLoading(true)
    await loadSessionMetadata()
    setIsLoading(false)
  }

  function onUnmount() {
    api.dispose()
  }

  const loadSessionMetadata = async () => {
    const session = await api.getSession(sessionId)
    const { name, description } = session
    setName(name)
    setDescription(description)
  }

  const updateSessionMetadata = async (name = 'Untitled', description) => {
    await api.updateSessionMetadata(sessionId, {
      name, description
    })
  }

  function renderInterleaved(){
    return <div style={{ flex: 1, overflow: 'auto', marginTop: 20 }}>
      <DocumentView sessionId={sessionId}/>
    </div>
  }

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%' }}>
      <div>
        <SessionTitle title={name} onCommit={(newName) => updateSessionMetadata(newName, description) && setName(newName)} />
        <SessionDescription text={description} onCommit={(newDescription) => updateSessionMetadata(name, newDescription) && setDescription(newDescription)} />
      </div>
      <div style={{ display: 'flex', marginTop: 15 }}>
          <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Icon icon="search"/><input type="text" style={{ width: '30%', height: '100%' }} />
            <div style={{ marginLeft: 20 }}>
              <Button onClick={ () => setMode('column') }><Icon icon="eye" style={{ color: mode === 'column' ?  '#00947e' : '#2e2e2e' }}/></Button>
              <Button onClick={ () => setMode('column') }><Icon icon="comment-dots" style={{ color: mode === 'column' ?  '#00947e' : '#2e2e2e' }}/></Button>
            </div>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Button onClick={ () => setMode('column') }><Icon icon="columns" style={{ color: mode === 'column' ?  '#00947e' : '#2e2e2e' }}/></Button>
            <Button onClick={ () => setMode('document') }><Icon icon="stream" style={{ color: mode === 'document' ? '#00947e' : '#2e2e2e' }} /></Button>
            <Button onClick={ () => setMode('file') }><Icon icon="file" style={{ color: mode === 'file' ?  '#00947e' : '#2e2e2e' }} /></Button>
          </div>
      </div>
      {mode === 'column'
      ? <TerminalPlayer sessionId={sessionId} socketUrl={socketUrl} />
      : renderInterleaved()
      }
    </div>
  )
}
