import React, { Fragment } from 'react'

export const ControlLabel = ({ children }) => {
  return (
    <label className="label">{children}</label>
  )
}

export const FormControl = ({ type, name, disabled, value, placeholder = '', onChange, autoFocus }) => {
  return (
    <Fragment>
      <div className="control has-icons-left has-icons-right">
        <input className="input is-success" autoFocus={autoFocus} name={name} disabled={disabled} type={type} placeholder={placeholder} value={value} onChange={onChange} />
        <span className="icon is-small is-left">
          <i className="fas fa-user"></i>
        </span>
        <span className="icon is-small is-right">
          <i className="fas fa-check"></i>
        </span>
      </div>
      <p className="help is-success">This username is available</p>
    </Fragment>
  )
}