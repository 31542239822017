import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
          <div className="content has-text-centered">
            <p>
              <strong>Distro</strong>Dino by <a href="#">Hytag Inc.</a>
            </p>
          </div>
        </footer>
    )
  }
}
