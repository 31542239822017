import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faCheckSquare, faCoffee, faCircle, faCheck, faCertificate, faSquare, faWallet,
  faAddressCard, faCube, faCubes, faExchangeAlt, faFileCode, faWrench, faTasks,
  faCogs, faCode, faProjectDiagram, faCog, faBars, faEllipsisH, faEllipsisV, faNetworkWired,
  faFileInvoiceDollar, faBeer, faSave, faCodeBranch, faFile, faSpinner, faPlay, faDownload,
  faArrowLeft, faPaste, faFire, faTimes, faTrash, faTerminal, faClock, faBug, faRocket, faGraduationCap,
  faSearch, faLightbulb, faUser, faList, faAngleDown, faStream, faColumns, faStar, faEye, faBookmark, faCommentDots
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faCheckSquare, faCoffee, faCircle, faCheck, faSquare, 
  faCertificate, faWallet, faAddressCard, faCubes, faCube, 
  faExchangeAlt, faFileCode, faCogs, faCode, faProjectDiagram, faCog,
  faBars, faEllipsisV, faEllipsisH, faWrench, faTasks, faNetworkWired,
  faFileInvoiceDollar, faBeer, faSave, faCodeBranch, faFile, faSpinner, faPlay, faDownload,
  faArrowLeft, faPaste, faFire, faTimes, faTrash, faTerminal, faClock, faBug, faRocket, faGraduationCap,
  faSearch, faLightbulb, faUser, faList, faAngleDown, faStream, faColumns, faStar, faEye, faBookmark, faCommentDots
)


export default class Icon extends Component {
  render() {
    const { icon, color: iconColor, style } = this.props
    return (
      icon === 'certificate'
        ? <span className="fa-layers fa-fw" style={{ ...style }}>
          <FontAwesomeIcon icon="certificate" color={iconColor} />
          <FontAwesomeIcon icon="check" inverse transform="shrink-6" />
        </span>
        : <FontAwesomeIcon icon={icon} style={{ color: iconColor, margin: 5, ...style }} pulse={icon === 'spinner'} />
    )
  }
}
