import React, { Component } from 'react'
import './Timeline.css'
import moment from 'moment'
import Editable from './InplaceEdit'
import Icon from './Icon'
import Markdown from './Markdown'
import { CommandBlock } from './CommandBlock'

class TimelineControls extends Component {
  render() {
    const { onPlayClicked = () => { } } = this.props
    return (
      <div
        style={{
          display: 'flex',
          border: '1px solid #efefef',
          backgroundColor: '#f3f3f3',
          height: 50,
          alignItems: 'center'
        }}
      >
        <div onClick={onPlayClicked}><Icon icon="play" /></div>
        <span style={{ marginLeft: 5 }}>00:00/05:00</span>
      </div>
    )
  }
}

class TimelineEntry extends Component {
  render() {
    const { event, onDescriptionChanged } = this.props
    const { title, time, description } = event
    return (
      <li className="event-item">
        <time dateTime="10:03">{moment(time).format('HH:mm')}</time>
        <div style={{ width: '100%' }}>
          <CommandBlock title={title} description={description} onDescriptionChanged={onDescriptionChanged} />
        </div>
      </li>
    )
  }
}


export default class Timeline extends Component {
  render() {
    let { events = [], onPlayClicked, onDescriptionChanged } = this.props
    // events = [{ description: 'foo', title: 'ls', time: Date.now() }]
    return (
      <div style={{ width: 400, overflowX: 'hidden', flex: 1, display: 'flex', flexDirection: 'column' }}>
        <TimelineControls onPlayClicked={onPlayClicked} />
        <ul className="events" style={{ margin: 0, width: '100%', overflowY: 'auto', flex: 1 }}>
          {events.map(ev => <TimelineEntry event={ev} key={ev.time} onDescriptionChanged={(text) => onDescriptionChanged(ev, text)} />)}
        </ul>
      </div>
    )
  }
}
