import React from 'react';
import Markdown from '../components/Markdown';
import { useHover } from '../libs/hooksLib';
import Terminal from '../components//Terminal'
import Icon from './Icon';
import Editable from './InplaceEdit';
import moment from 'moment';

export function CommandBlock({ time, title="", description="", output='', onDescriptionChanged }) {
  const [hoverRef, isHovered] = useHover();

  // TODO debounce?
  async function handleToggleVisibility(e) {
    e.stopPropagation(); 
    e.preventDefault(); 
    console.log('clicked'); 
  }

  return(
    <div
      ref={hoverRef} 
      style={{ 
      backgroundColor: 'white', 
        display: 'flex', 
        flexDirection: 'column',
        marginBottom: 20,
      }}>
      <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 5 }}>
        <div>
        {
          time && <time dateTime="10:03">{moment(time).format('HH:mm')}</time>
        }
        <div style={{ margin: 0, fontSize: '.75rem', fontWeight: 'bold', display: 'inline' }}>{title}</div>
        </div>
        <div style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
          <div style={{ display: 'inline'}} onMouseDown={handleToggleVisibility}><Icon icon="eye" /></div>
          <Icon icon="bookmark" />
        </div>
      </div>
      <Editable text={description} onCommit={onDescriptionChanged} style={{ width: '100%' }} >
        <Markdown 
          source={description} 
          style={{ 
            outline: isHovered ? '1px solid #999' : 'none',
            minHeight: '2rem', 
            margin: 10 
          }}
        />
      </Editable>
      </>
      {output &&
      <>
      <div style={{ margin: 5, fontSize: '.75rem', fontWeight: 'bold', color: '#999' }}>Output:</div>
      <div style={{  maxWidth: '100%', display: 'flex', margin: 10 }}><Terminal content={output} rows={Math.min(output.split('\n').length, 25)} /></div>
      </>
      }
    </div>
  )
}