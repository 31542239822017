import LinkedListNode from "./LinkedList"

// splits an array with a predicate function like string.split
const splitArray = (arr, pred) => {
  let parts = []
  while(true) {
    let idx = arr.findIndex(pred)
    if (idx === -1){
      break
    }
    let part = arr.splice(0, idx +1)
    part.pop() // remove searched item (not inkl.)
    // do not include empty sub-arrays
    if (part.length > 0) {
      parts.push(part)
    }
  }
  return parts
}

// pairwise swap, skips first element
/*
const swap = (array) => {
  for (let index = 1; index < array.length-1; index+=2) {
    const element = array[index];
    array[index] = array[index+1]
    array[index+1] = element
  }
  return array
} 
*/

class SessionEvent {
  constructor(h, o) {
    this.h = h
    this.o = o
  }
  get time() {
    return this.h[0]
  }
  get command(){
    return this.h[2]
  }
  get description() {
    return this.h[3]
  }
  get output(){
    return this.o[2]
  }
}

// TODO move to server?
/**
 * 
 * Custom DataStructure based on LinkedList to store
 * and manage interleaved multi-type events such as commands and their output
 */
 export default class EventList extends LinkedListNode {
   constructor(historyEvents, outputEvents) {
      super()

      // determine the prompt
      let prompt = outputEvents[0][2]

      const partitions = splitArray(outputEvents, ([t, ty, out]) => out === prompt)
      let _outputEvents = []
      for (const partition of partitions) {
        let start = partition[0]
        partition.unshift([0, 'o', prompt])
        let out = partition.map(p => p[2]).join('')
        _outputEvents.push([start[0], 'o', out])
      }

      historyEvents = historyEvents.sort(([t1], [t2]) => t1-t2)
      _outputEvents = _outputEvents.sort(([t1], [t2]) => t1-t2)
      _outputEvents.unshift([0, 'o', ''])

      console.log('outs', _outputEvents, historyEvents)

      // let events = [...historyEvents, ...outs].sort(([t1], [t2]) => t1-t2 )

      // TODO make sure both array have same length

      let events = []
      for (let idx = 0; idx < historyEvents.length; idx++) {
        const h = historyEvents[idx];
        const o = _outputEvents[idx];
        events.push(new SessionEvent(h, o))        
      }

      // the history event is emitted AFTER the output. therefore we do a pairwise swap on the array
      // ['s', 'o', 'h', 'o', 'h'] => ['s', 'h', 'o', 'h', 'o']
      // this.events = swap(events)
      
      this.events = events

   }

   getEvents() {
     return this.events
   }
 }