import React, { Component } from 'react'
import Button from '../components/Button'

class PriceCard extends Component {
  render() {
    const { plan } = this.props
    const { name, amount = "NaN", color='purple', features = [], type = '' } = plan
    const highlight = type === 'optimal'
    return (
      <div className="card" style={{ margin: 10, width: (highlight? 310 : 280), height: highlight? 430 :400, boxShadow: `0 0.5em 1em -0.125em rgba(10,10,10,${highlight? '.2' : '.1'}), 0 0 0 1px rgba(10,10,10,.02)` }}>
        <div className="card-content">
          <div className="media-content">
            <p className="title is-4">{name}</p>
          </div>
          <div className="plan-price">
            <span className="plan-price-amount" style={{
              fontSize: highlight? '2.75rem' : '2.5rem',
              marginTop: highlight? 10 : 0
            }}>
              <span style={{
                verticalAlign: 'super',
                fontSize: '1rem',
                color: '#4a4a4a',
                marginRight: '.25rem'
              }} className="plan-price-currency">$</span>
              <span
                style={{
                  color: color,
                  fontWeight: 'bold',
                  margin: 5
                }}
              >{amount}</span>
            </span>
            /month
          </div>
          <div>
            <table className="table is-striped is-fullwidth" style={{ marginTop: (highlight? 25 : 25) }}>
              <tbody>
                { features.map(f => <tr><td>{f}</td></tr>)}
              </tbody>
            </table>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: (highlight? 25 : 20) }}>
            <Button>Choose</Button>
          </div>  

        </div>
      </div>
    )
  }
}


export default class PricingTable extends Component {
  render() {
    const plans = [
      { name: 'Free', currency: '$', amount: 0, color: '#ffdd57', features: [
        '3 sessions /month',
        '5 min session limit',
        'non-interactive links',
        'micro instance'
      ] },
      { name: 'Starter', currency: '$', amount: 12, color: '#90dcee', features: [
        '10 sessions /month',
        '3 hr session limit',
        'non-interactive links',
        'mini instance'
      ] },
      { name: 'Flex', currency: '$', amount: 39, color: '#1cf360', type: 'optimal', features: [
        '∞  sessions /month',
        'no session limit',
        '5 interactive links',
        'custom vm spec'
      ] },
      { name: 'Enterprise', currency: '$', amount: 79, color: '#576bff', features: [
        '∞  sessions /month',
        'no session limit',
        'all links are interactive',
        'dedicated hardware'
      ] },
    ]
    return (
      <div style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center'
      }}>
        {plans.map(plan => <PriceCard plan={plan} />)}
      </div>
    )
  }
}
