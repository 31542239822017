import React, { useEffect, useState } from 'react'
import Button from '../components/Button'
import { Auth } from "aws-amplify";
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import { Drowpdown, DrowpdownItem } from '../components/Dropdown';
import Checkbox from '../components/Checkbox';
import { getAPI } from '../js/WsApi';
import { SystemInfo } from './SystemInfo';
import { useAppContext } from '../libs/contextLib';
import { Modal } from '../components/Modal';
import { ImportModal } from '../components/ImportModal';
import { shareEnvironment } from '../js/Api';

export default function Environments() {

  const [user, setUser] = useState({})
  const [environments, setEnvironments] = useState([])
  const [checkedTableRows, setCheckedTableRows] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const history = useHistory()

  const { config } = useAppContext();
  const api = getAPI(config)

  useEffect(() => {
    onLoad();
    return onUnmount
  }, [config]);

  // method should be called whenever config changes
  async function onLoad() {

    try {
      let session = await Auth.currentSession();
      const { idToken } = session
      setUser({
        ...idToken.payload
      })
      console.log('session', session)
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
      console.log('e', e)
    }

    await loadEnvironments()
  }

  function onUnmount() {
    if(!api) return
    api.dispose()
  }

  async function loadEnvironments() {
    const environments = await api.listEnvironments()
    setEnvironments(environments)
  }

  async function createEnvironment() {
    const result = await api.createEnvironment({
      image: 'alpine'
    })
    loadEnvironments()
  }

  async function shareEnv() {
    const data = {
      creator: 'Philipp',
      environment: {
        name: 'Centrifuge Validator',
        description: 'This is a validator :)'
      },
      config: {
        host: 'ec2-3-236-254-210.compute-1.amazonaws.com',
        port: 4000,
        environmentId: '12345',
        snapshotId: 'abcdefg'
      }
    }
    const result = await shareEnvironment(data)
    console.log('share env result', result)
  }

  async function handleSelectEnvironment(envId) {
    console.log('api closure??', api)
    const result = await api.startSession(envId)
    history.push(`/dashboard/environment/${envId}/session/${result.sessionId}`)
  }

  async function handleSelectSessions(envId) {
    history.push(`/dashboard/environment/${envId}/session`)
  }

  return (
    <div style={{ flex: 1 }}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>User environments for: {user.email}</div>
        <SystemInfo style={{ flex: 1 }} />
      </div>
      <div>
        <Button onClick={createEnvironment}>New</Button>
        <Drowpdown>
          {['Delete'].map(item => <DrowpdownItem key={item} label={item} onClick={
            async () => {
              console.log('delete', Object.keys(checkedTableRows))
              let sessionId
              for(sessionId of Object.keys(checkedTableRows)) {
                if (checkedTableRows[sessionId]) {
                  // await deleteSession(sessionId)
                }
              }
              await onLoad()
            }
          } />)}
        </Drowpdown>
        <Button onClick={shareEnv}>Share</Button>
      </div>

      <table className="table" style={{ marginTop: 10 }}>
        <thead>
          <tr>
            <th><Checkbox checked={selectAll} onChange={(e) => {
              let val = {}
              environments.map(s => val[s.Id] = !selectAll)
              setCheckedTableRows(val)
              setSelectAll(!selectAll)
            }}/></th>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Created</th>
            <th># Sessions</th>
            <th>Scope</th>
            <th>Image</th>
            <th>State</th>
            <th>CPU</th>
          </tr>
        </thead>
        <tbody>
          {environments.map(({ Id, Name, Created, Description = '', Config, State, sessionCount }) =>
            <tr key={Id}>
              <td><Checkbox checked={!!checkedTableRows[Id]} onChange={() => setCheckedTableRows({ ...checkedTableRows, [Id] : checkedTableRows[Id] ? false : true })}/></td>
              <td onClick={() => handleSelectEnvironment(Id)} style={{ cursor: 'pointer' }}>{Id}</td>
              <td onClick={() => handleSelectEnvironment(Id)} style={{ cursor: 'pointer' }}>{Name}</td>
              <td>{Description}</td>
              <td>{moment(Created).format("DD.MM.YYYY hh:mm:ss")}</td>
              <td onClick={() => handleSelectSessions(Id)} style={{ cursor: 'pointer' }}>{sessionCount}</td>
              <td>Public</td>
              <td>{Config.Image}</td>
              <td>{State.Running ? 'running' : 'stopped'}</td>
              <td>{'unknown'}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
