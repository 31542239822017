import React, { Component } from 'react'

export default class Button extends Component {
  render() {
    const { onClick = () => { }, disabled = false } = this.props
    return (
      <button
        style={{
          ...this.props.style
        }}
        className="button"
        disabled={disabled}
        onClick={onClick}
      >
        {this.props.children || this.props.label}
      </button>
    )
  }
}
