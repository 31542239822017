import React, { useEffect, useState } from 'react'
import Icon from '../components/Icon'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { getEnvironmentDetails } from '../js/Api'
import { Modal } from '../components/Modal'
import { ImportModal } from '../components/ImportModal'

export function Share() {

  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(undefined)
  const [showModal, setShowModal] = useState(false)
  const [details, setDetails] = useState(undefined)

  const match = useRouteMatch()
  const { params } = match
  const { env } = params

  const history = useHistory()

  useEffect(() => {
    onLoad()
  }, []);

  async function onLoad() {
    setIsLoading(true)
    const details = await getEnvironmentDetails(env)
    if (!details || 'error' in details) {
      setHasError('Something went wrong')
    } else {
      console.log('details', details)
      setDetails(details)
      setTimeout(() => {
        setShowModal(true)
      }, 1000)
    }
    setIsLoading(false)
  }

  function renderLoading() {
    return (
      <div style={{ marginTop: '10%', height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <h1>Loading info for shared environment</h1>
        <Icon style={{ fontSize: '4rem'}} icon="spinner"/>
      </div>
    )
  }

  function handleConfirm() {
    setShowModal(false)
    const envId = 'a51c5a3b'
    const sessionId = 'rVZ9QX'
    history.push(`/dashboard/environment/${envId}/session/${sessionId}`)
  }

  return (
    <div>
      { isLoading 
      ? renderLoading() 
      : (
        hasError
        ? <div>Error: {hasError}</div>
        : (
          <div>
             <div style={{ margin : 80}}>If you closed the modal accidentally you can reload the page</div>
             <Modal show={showModal} button="Ok" onClose={() => setShowModal(false)} onConfirm={handleConfirm}>
              <ImportModal info={details}/>
            </Modal>
          </div>
        ) 
      )
      }
    </div>
  )
}
