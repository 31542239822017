import React, { Component, Fragment } from 'react'
import Button from './Button'

export class Modal extends Component {
  render() {
    let { show, children, button, onClose, onConfirm } = this.props
    return (
      <Fragment>
        <div className={"modal" + (show ? " is-active" : "")} style={{ justifyContent: 'start', paddingTop: '10%' }}>
          <div className="modal-background"></div>
          <div className="modal-content" style={{ backgroundColor: 'white', borderRadius: 10, height: 500, padding: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
                {children}
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: 'auto' }}>
                  <Button onClick={onClose} style={{ margin: 5 }}>{"Cancel"}</Button>
                  <Button onClick={onConfirm} style={{ margin: 5 }}>{button}</Button>
                </div>
              </div>
            </div>  
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={onClose}></button>
        </div>
      </Fragment>
    )
  }
}
