import React, { Component } from 'react'
import moment from 'moment'

export default class Countdown extends Component {
  state = {
    date: Date.now(),
    expires: Date.now() + (5 * 60 * 1000)
  }
  componentDidMount = () => {
    this.interval = setInterval(() => {
      this.setState({
        date: Date.now()
      })
    }, 500)
  }
  componentWillUnmount = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
  render() {
    const { date, expires } = this.state
    let diffTime = expires - date
    let duration = moment.duration(diffTime, 'milliseconds')
    return (
      <span style={{ marginLeft: 5, fontSize: '1.5em', ...this.props.style }}>{`${duration.minutes() < 10 ? 0 : ''}${Math.max(duration.minutes(), 0)}:${duration.seconds() < 10 ? 0 : ''}${Math.max(0, duration.seconds())}`}</span>
    )
  }
}
