import React, { useState } from 'react'
import Button from './Button'
import { FormGroup } from './form/FormGroup'
import { FormControl, ControlLabel } from './form/FormControl'
import Icon from './Icon'

function Radio({ name, disabled, checked, label, hint }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: 10}}>
      <input type="radio" id="you" name={name} value="you" checked={checked} disabled={disabled} />
      <div style={{ marginLeft: 10 }}>
        <label for="you" style={{ fontWeight: 'bold' }}>{label}</label> <br />
        {hint && <div style={{ fontSize: '0.75rem' }}>{hint}</div>}
      </div>
    </div>
  )
}

export default function ShareForm() {
  const [shareLink, setShareLink] = useState('http://dino.co/s/12ab34z')
  const showDetails = false
  return(
    <div style={{ 
      flex: 1, 
      backgroundColor: 'white', 
      height: '100%', 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      overflowY: 'auto',
      padding: 50
    }}>
      <FormGroup>
          <ControlLabel>Link to share</ControlLabel>
          <FormControl
            name="text"
            disabled
            autoFocus
            type="email"
            value={shareLink}
            onChange={() => {}}
          />
      </FormGroup>
      <div>Settings:</div>
      <div>
        <span style={{ marginRight: 20 }}><span>Host: </span><strong>You</strong><Icon icon="caret-down" /></span>
        <span style={{ marginRight: 20 }}><span>Environment: </span><strong>Clone</strong></span>
        <span style={{ marginRight: 20 }}><span>Access Control: </span><strong>Anyone with link</strong></span>
        <span style={{ marginRight: 20 }}><span>Snapshot: </span><strong>None</strong></span>
      </div>
      <div style={{ marginTop: 30 }}>
        <Button style={{ backgroundColor: '#9d6fc7', color: 'white' }}>Create</Button>
      </div>
      {showDetails &&
      <>
       <ControlLabel>Host Instance</ControlLabel>
      <FormGroup>
        <Radio type="radio" name="host" label="You" checked={true} hint="Environments are hosted on one instance. Recommended max. 5"/>
        <Radio type="radio" name="host" label="They" hint="Hosted on separate instances. Requires registration."/>
        <Radio type="radio" name="host" label="Choose" hint="Let user choose"/>
      </FormGroup>
      <ControlLabel>Sharing Options</ControlLabel>
      <FormGroup>
        <Radio type="radio" name="clone" label="Clone environment for everyone" checked />
        <Radio type="radio" name="clone" label="One environment - shared access"  />
      </FormGroup>
      <ControlLabel>Access Control</ControlLabel>
      <FormGroup>
        <Radio type="radio" name="access" label="Anyone with the link" checked />
        <Radio type="radio" name="access" label="Private (email invite)" disabled />
      </FormGroup>
      </>
      }
    </div>
  )
}