import React from 'react'
import LoginForm from '../components/LoginForm'
import { useAppContext } from '../libs/contextLib';

/*
export default class Login extends Component {
  render() {
    return (
      <div style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start'
      }}>
        <div
          className="card"
          style={{
            width: 500,
            marginTop: 200,
          }}>
          <h3 className="title" style={{ textAlign: 'center', paddingTop: 5 }}>Login</h3>
          <hr className="login-hr" />
          <form action="" className="box">
            <div className="field">
              <label for="" className="label">Email</label>
              <div className="control has-icons-left">
                <input type="email" placeholder="e.g. bobsmith@gmail.com" className="input" required />
                <span className="icon is-small is-left">
                  <i className="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div className="field">
              <label for="" className="label">Password</label>
              <div className="control has-icons-left">
                <input type="password" placeholder="*******" className="input" required />
                <span className="icon is-small is-left">
                  <i className="fa fa-lock"></i>
                </span>
              </div>
            </div>
            <div className="field">
              <label for="" className="checkbox">
                <input type="checkbox" />
               Remember me
              </label>
            </div>
            <div className="field">
              <button className="button is-success">
                Login
              </button>
            </div>
          </form>

        </div>
      </div>
    )
  }
}
*/

export default function Login() {
  const { userHasAuthenticated } = useAppContext();

  return (
    <div style={{
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'flex-start'
    }}>
      <div
        className="card"
        style={{
          width: 500,
          marginTop: 200,
        }}>
        <LoginForm />
      </div>
    </div>
  )
}
