import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Button from './Button'
import Icon from './Icon'

export default function SideBar({ navItems }) {
  const [minimized, setMinimized] = useState(true)
  return (
    <div style={{ 
      backgroundColor: 'white',
      width: `${minimized ? 50 : 300}px`, 
      minWidth: `${minimized ? 50 : 300}px`, 
      overflow: 'hidden',
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      boxShadow: 'rgba(31, 30, 30, 0.1) 2px 0px 4px 0px',
    }}>
      <Button onClick={() => setMinimized(!minimized)}><Icon icon="bars"/></Button>
      <div>
        {
          navItems.map(n => <div key={n.to}><NavLink to={n.to}><Icon icon={n.icon} /> {!minimized && n.label}</NavLink></div>)
        }
      </div>
    </div>
  )
}
