import React, { Component, Fragment, useState, useEffect } from 'react'
import './App.css'
import Footer from './components/Footer'

import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";

import {
  Link, useHistory, useLocation
} from "react-router-dom";
import Routes from './Routes'


class NavItem extends Component {
  render() {
    const { onClick = () => { } } = this.props
    return (
      <span style={{ padding: 5, marginRight: 10, color: 'white' }} onClick={onClick}>
        {this.props.children}
      </span>
    )
  }
}

/*

class Pro extends Component {
  render() {
    return (
      <sup style={{ fontWeight: 'bold', padding: 4, marginTop: -10 }}>PRO</sup>
    )
  }
}
*/

function LinkContainer({ to, children }) {
  return (
    <p className="level-item"><Link to={to}>{children}</Link></p>
  )
}

export default function App() {

  const [isAuthenticated, userHasAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const history = useHistory();
  const location = useLocation()

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      let session = await Auth.currentSession();
      userHasAuthenticated(true);
      console.log('session', session)
      // FIXME this kills the routes on the path
      // history.push("/dashboard")
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
      console.log('e', e)
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/login");
  }

  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {isAuthenticating
        ? <div>Loading</div>
        : (<Fragment>
          <section className="hero is-primary" style={{ width: '100%', minHeight: 60, background: 'linear-gradient(141deg,#009e6c 0,#00d1b2 71%,#00e7eb 100%)' }}>
            <nav className="level" style={{ padding: 5, margin: 0 }}>
              <div className="level-left">
                <div className="level-item">
                  <p className="subtitle is-5">
                    <Link to="/"><span style={{ fontWeight: 'bold'}}>Distro</span><span style={{ textDecoration: 'none', color: '#4e4e4e', fontWeight: 'bold' }}>Dino</span></Link>
                  </p>
                </div>
              </div>
              <div className="level-right" style={{ marginTop: 5, fontWeight: 'bold' }}>
                {false && !isAuthenticated && <p className="level-item"><Link to="/pricing">Pricing</Link></p>}
                {isAuthenticated && <p className="level-item"><Link to="/dashboard">Dashboard</Link></p>}
                {isAuthenticated
                  ? <NavItem onClick={handleLogout}>Logout</NavItem>
                  : <>
                    <LinkContainer to="/signup"> <NavItem>Signup</NavItem>
                    </LinkContainer> <LinkContainer to="/login">
                      <NavItem>Login</NavItem> 
                    </LinkContainer>
                  </>
                }

              </div>
            </nav>
          {(!location.pathname.startsWith('/dashboard')) && 
            <div className="hero-body" style={{ padding: 15, background: 'none' }}>
              <div className="container" style={{ textAlign: 'center' }}>
                <h1 className="title">
                  Create, demo and share reproducible environments with a link
              </h1>
                <h2 className="subtitle">
                  Don't waste time on installation and configuration.
                <br />
                </h2>
              </div>
            </div>
          }
          </section>
          <main style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            margin: 0,
            overflow: 'auto'
          }}
            className="content"
          >
            <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
              <Routes />
            </AppContext.Provider>
          </main>
          <Footer />
        </Fragment>
        )}
    </div>
  )
}

